import React, { CSSProperties } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

import ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface =
    Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

interface ITextInputProps {
    value: string;
    placeholder: string;
    handleChange: (value: string) => void;
    className: string;
    handleEnter?: () => void;
    dataTest?: string;
    style?: CSSProperties;
    hasError?: boolean;
    rulePerLine?: ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;
    isEmbroideryLockdown?: boolean;
    isSectionInvalid?: boolean;
}

export const TextInput: React.FC<ITextInputProps> = ({
    className,
    value,
    placeholder,
    dataTest,
    handleChange,
    handleEnter,
    style,
    hasError,
    rulePerLine,
    isEmbroideryLockdown,
    isSectionInvalid,
}) => {
    const isRequiredInLockdown = Boolean(isEmbroideryLockdown && rulePerLine?.is_required);
    const isRequiredInvalid = isRequiredInLockdown && isSectionInvalid && (!value || !value.length);
    return (
        <div className={className}>
            {rulePerLine && rulePerLine.label && (
                <span className={classes(styles.textLabel, {
                    [styles.requiredLine]: isRequiredInLockdown,
                })}
                >
                    {rulePerLine.label}
                </span>
            )}

            <input
                value={value}
                className={classes(styles.textInput, {
                    [styles.error]: hasError || isRequiredInvalid,
                })}
                placeholder={placeholder}
                data-test={dataTest}
                onChange={ev => handleChange(ev.target.value)}
                onKeyPress={(ev) => {
                    if (ev.charCode === 13 && handleEnter) {
                        handleEnter();
                    }
                }}
                style={style}
                type="text"
            />
        </div>
    );
};
