import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { Section } from './section';
import { SectionError } from './section-error';
import { TextContainer } from './text-container';
import { LogoContainer } from './logo-container';
import { FlagContainer } from './flag-container';
import { SpecialInstructionsContainer } from './special-instruction-container';
import styles from './style.css';

interface ISectionsProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any;
}

const staticOptionLabel: {[key: string]: string} = {
    text: 'Text',
    logos: 'Logos',
    flags: 'Flags',
    'special-instructions': '',
};

export const Sections = ({ embroiderer, embroideryPage }: ISectionsProps) => {
    const { isSaveFailed } = embroiderer;
    return (
        <div className={styles.sections}>
            {embroiderer.options.map((option) => {
                const isSectionInvalid = SectionError({
                    isSaveFailed,
                    option,
                    embroiderer,
                });

                if (option.type === 'text') {
                    const currentPageData = extractState(embroiderer, 'text');
                    if (!currentPageData.embTextLines.length) {
                        return null;
                    }
                }

                return (
                    <Section
                        key={option.label}
                        className={option.type}
                        handleEdit={embroideryPage.openOption(option)}
                        handleToggle={embroideryPage.toggleOption(option)}
                        optionType={option.type}
                        isLocked={option.isLocked}
                        label={staticOptionLabel[option.type]}
                        isSectionInvalid={isSectionInvalid}
                    >
                        {option.type === 'text' && (
                            <div className={styles.containerWrapper}>
                                <TextContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                    isSectionInvalid={isSectionInvalid}
                                    isSaveFailed={isSaveFailed}
                                />
                            </div>
                        )}
                        {option.type === 'logos' && (
                            <div className={styles.containerWrapper}>
                                <LogoContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                />
                            </div>
                        )}
                        {option.type === 'flags' && (
                            <div className={styles.containerWrapper}>
                                <FlagContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                />
                            </div>
                        )}
                        {option.type === 'special-instructions' && (
                            <div className={styles.containerWrapper}>
                                <SpecialInstructionsContainer embroiderer={embroiderer} />
                            </div>
                        )}
                    </Section>
                );
            })}
        </div>
    );
};
