import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ISectionProps {
    className?: string;
}

export const Section: React.FC<ISectionProps> = ({ className = '', children }) => (
    <div className={classes(styles.section, className)}>
        {children}
    </div>
);
