import React, { useState } from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ErrorMessage } from 'ui/component/embroidery-configurator/components/error-message';
import styles from './style.css';

export const SpecialInstructionsContainer = ({ embroiderer }) => {
    const t = usePhraseTranslater();
    const currentPageData = extractState(embroiderer, 'special-instructions');
    const SpecialInstructionsComponents = types['special-instructions'];
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <div className={styles.instructionContainer}>
            <SpecialInstructionsComponents.State embroiderer={embroiderer} data={currentPageData}>
                {({ embroideryPage }) => {
                    const handleTextChange = (ev) => {
                        const text = ev.target.value;
                        if (text.length >= 255) {
                            setShowError(true);
                            setErrorMessage('Text cannot exceed 255 characters.');
                        } else {
                            setShowError(false);
                            setErrorMessage('');
                            embroideryPage.setText(text);
                        }
                    };

                    return (
                        <>
                            <textarea
                                data-test="special-instructions"
                                className={styles.instructions}
                                maxLength={255}
                                onChange={handleTextChange}
                                value={embroideryPage.text}
                                placeholder={t('Special Instructions')}
                            />
                            <ErrorMessage show={showError} message={errorMessage} />
                        </>
                    );
                }}
            </SpecialInstructionsComponents.State>
        </div>
    );
};
