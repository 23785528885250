import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IUploadButtonProps {
    dataTest: string;
    className?: string;
    dataTestNew?: string;
    handleChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UploadButton: React.FC<IUploadButtonProps> = ({
    dataTest,
    handleChange,
    dataTestNew = null,
    className = null,
    children,
}) => (
    <div className={
        classes({
            [styles.file]: true,
            [className]: Boolean(className),
        })}
    >
        <input data-test={dataTest} data-test-new={dataTestNew} type="file" onChange={handleChange} />
        {children}
    </div>
);
