import { isImageFile } from 'ui/component/embroidery-configurator/pages/logos/configure/logo/logo-upload/logo-preview';

interface TextLine {
    text: string;
    font: string;
    color: string;
    size?: number;
}

interface Logo {
    name: string;
    colors: string;
    position: string;
}

interface LiquidPixelConfigObj {
    baseUrl: string;
    scaleFactor: number;
    prodID: string;
    prodColor: string;
    prodView: string | null;
    textLines: Array<TextLine>;
    textPosition: string;
    logos: Array<Logo>;
    flag1: string;
    flag1Position: string;
    flag2: string;
    flag2Position: string;
    seed: string; // invalidate the cache
    call: string;
    sink: string;
}

interface EmbroideryPage {
    type?: string;
}

interface EmbroideryPlacementOption {
    face: string;
    frontendValue: string;
}

// Do not grow this interface; Instead replace the usage with IEmbroideryOption
interface EmbroideryOption {
    type: string;
    placementOptions?: Array<EmbroideryPlacementOption>;
}

// we are using Component-Scoped typing to make liquid-pixel component minimal.
// Otherwise, options: Array<EmbroideryOption> Type should replace with options: IEmbroideryOption[].
interface LiquidPixelConfigProps {
    baseUrl: string;
    productId: string;
    productColor: string;
    face?: string;
    options: Array<EmbroideryOption>;
    embroideryPage?: EmbroideryPage;
}

export class LiquidPixelConfig {
    private readonly placementsFacesMap: {};

    private usedFaces: Array<string>;

    private static encodeLiquidPixelCharacters(text: string) {
        return encodeURI(text.replace(/"/g, 'U0022')
            .replace(/#/g, '%23')
            .replace(/&/g, 'U0026amp;')
            .replace(/'/g, 'U0027')
            .replace(/,/g, 'U002C')
            .replace(/á/g, 'U00E1')
            .replace(/é/g, 'U00E9')
            .replace(/í/g, 'U00ED')
            .replace(/ó/g, 'U00F3')
            .replace(/ú/g, 'U00FA')
            .replace(/ñ/g, 'U00F1'));
    }

    private static extractColorCode(productColor: string) {
        const matches = productColor.match(/\(([\w]+)\)/);
        if (!matches || matches.length < 2) {
            return productColor;
        }

        return matches[1];
    }

    private previewUrl?: string;

    private zoomPreviewUrl?: string;

    protected config: LiquidPixelConfigObj;

    private readonly props: LiquidPixelConfigProps;

    private readonly logoPlaceholderImage = 'https://www.chefworks.com/media/wysiwyg/Placeholder_Logo_Emb.png';

    constructor(props: LiquidPixelConfigProps) {
        this.props = props;

        const { productId, productColor, baseUrl } = props;

        const extractedColorCode = LiquidPixelConfig.extractColorCode(productColor);

        this.config = {
            baseUrl,
            scaleFactor: 3,
            prodID: productId,
            prodColor: extractedColorCode,
            prodView: 'front',
            textLines: [],
            textPosition: '',
            logos: [],
            flag1: '',
            flag1Position: '',
            flag2: '',
            flag2Position: '',
            seed: '001', // invalidate the cache
            call: 'url[file:main]',
            sink: 'format[jpg]',
        };

        this.placementsFacesMap = {};
        this.usedFaces = [];
    }

    getPreviewUrl() {
        if (!this.previewUrl) {
            this.constructPreviewUrl();
        }

        return this.previewUrl;
    }

    getZoomPreviewUrl() {
        return this.zoomPreviewUrl;
    }

    private getProdViewFromFace(faceString?: string) {
        const { face } = this.props;
        let fromFace = (typeof faceString === 'undefined') ? face : faceString;

        if (!fromFace) {
            return null;
        }

        fromFace = fromFace.toLowerCase();

        // TODO: This will likely break when i18n kicks in,
        //  if there is an option to convert to default language we should
        //  use it here before comparing the strings
        if (fromFace.indexOf('left') >= 0) {
            return 'leftside';
        } if (fromFace.indexOf('right') >= 0) {
            return 'rightside';
        } if (fromFace.indexOf('back') >= 0) {
            return 'back';
        }
        return fromFace;
    }

    constructPreviewUrl() {
        this.constructConfigFromEmbroidery();
        if (this.previewUrl && !this.needsRegeneratingPreviewUrl()) {
            return;
        }

        this.config.prodView = this.getProdViewFromFace() || null;

        const stateKeys = Object.keys(this.config);
        this.previewUrl = this.config.baseUrl;
        this.zoomPreviewUrl = this.config.baseUrl;
        // previewSize[medium] = 1500x1500 and previewSize[small] = 750x750
        let paramsUrl = 'previewSize[small],';
        let zoomParamsUrl = 'previewSize[large],';
        for (let i = 0; i < stateKeys.length; i += 1) {
            paramsUrl += this.generateURLParams(stateKeys[i]);
            zoomParamsUrl += this.generateURLParams(stateKeys[i], true);
        }
        this.previewUrl += paramsUrl;
        this.zoomPreviewUrl += zoomParamsUrl;
    }

    private generateURLParams(key: string, forZoom = false) {
        let currentUrl = '';
        if (this.config[key] === undefined) {
            return currentUrl;
        }

        switch (key) {
            case 'baseUrl':
                break;
            case 'textLines': {
                const textLines = this.config[key];
                for (let j = 0; j < textLines.length; j += 1) {
                    const textLine = textLines[j];
                    const currentFontKey = `font${(j + 1).toString()}`;
                    let currentFontSize = null;
                    if (textLine.size) {
                        currentFontSize = forZoom ? textLine.size * 4 : textLine.size;
                    }
                    currentUrl += (
                        `textline${(j + 1).toString()}[${textLine.text}],` +
                        `${currentFontKey}[${textLine.font}],` +
                        `${currentFontKey}color[${encodeURI(textLine.color)}],` +
                        `${currentFontKey}size[${currentFontSize || ''}],`
                    );
                }
                break;
            }
            case 'logos': {
                const logos = this.config[key];
                for (let j = 0; j < logos.length; j += 1) {
                    const logo = logos[j];
                    const logoKey = `logo${(j + 1).toString()}`;
                    currentUrl += (
                        `${logoKey}[${encodeURI(logo.name)}],` +
                        `${logoKey}colors[${encodeURI(logo.colors)}],` +
                        `${logoKey}Position[${encodeURI(logo.position)}],`
                    );
                }
                break;
            }
            case 'call':
            case 'sink':
                currentUrl += `${key}=${this.config[key]}`;
                if (key !== 'sink') {
                    currentUrl += '&';
                }
                break;
            default:
                currentUrl += `${key}[${encodeURI(this.config[key])}]`;
                if (key === 'seed') {
                    currentUrl += '&';
                } else {
                    currentUrl += ',';
                }
                break;
        }

        return currentUrl;
    }

    private constructConfigFromEmbroidery(recordUsedFace = false) {
        const { options, embroideryPage } = this.props;

        options.forEach((o) => {
            // Ignore special instructions
            if (o.type === 'special-instructions') {
                return;
            }

            const option = embroideryPage && o.type === embroideryPage.type ? embroideryPage : o;
            // eslint-disable-next-line max-len
            const typeCamelCase = o.type.charAt(0).toUpperCase() + o.type.replace(/-([a-z])/g, g => g[1].toUpperCase()).slice(1);

            this[`construct${typeCamelCase}Config`](option, recordUsedFace);
        });
    }

    private addUsedFaceFromPlacement(placement: string|Array<string>) {
        const placements = (typeof placement === 'string') ? [placement] : placement;

        placements.forEach((p) => {
            const faceUsed = this.placementsFacesMap[p];
            if (!faceUsed || this.usedFaces.indexOf(faceUsed) >= 0) {
                return;
            }
            this.usedFaces.push(faceUsed);
        });
    }

    // @ts-ignore
    private constructTextConfig(option, recordUsedFace = false) {
        const textLines = [];
        const color = option.color ? `m${option.color.split(' ').pop()}` : null;
        // *** Temporarily removing font size in parameters
        // const fontSize = Math.round(
        //     convertFromInchesToPoints(previewFontSize(option, 22, true))
        // );
        option.lines.forEach((line) => {
            if (!line || !color || !option.font) {
                return;
            }
            textLines.push({
                text: LiquidPixelConfig.encodeLiquidPixelCharacters(line),
                font: option.font,
                color,
                // size: fontSize,
            });
        });
        this.config.textLines = textLines;
        this.config.textPosition = option.liquidPixelPlacement || option.placement;

        if (recordUsedFace) {
            this.addUsedFaceFromPlacement(option.liquidPixelPlacement || option.placement);
        }
    }

    /* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
    // @ts-ignore
    private constructLogosConfig(option, recordUsedFace = false) {
        const logos: Array<Logo> = [];
        option.logos.forEach((logo) => {
            if (!logo.logo || !logo.placement) {
                return;
            }

            switch (logo.logo.type) {
                case 'on-file':
                    if (!logo.logo.valueObj || !logo.logo.valueObj.value) {
                        return;
                    }
                    break;
                case 'upload':
                    if (!logo.logo.imageUrl) {
                        return;
                    }
                    break;
                default:
                    break;
            }

            // Adding placeholder image if path is null because of custom input in design number
            let imagePath;
            if (logo.logo.type === 'on-file') {
                imagePath = logo.logo.valueObj.path || this.logoPlaceholderImage;
            } else {
                imagePath = logo.logo.imageUrl;
                if (!isImageFile(imagePath)) {
                    imagePath = this.logoPlaceholderImage;
                }
            }

            logos.push({
                name: imagePath,
                colors: '',
                position: logo.liquidPixelPlacement || logo.placement,
            });

            if (recordUsedFace) {
                this.addUsedFaceFromPlacement(logo.placement);
            }
        });
        this.config.logos = logos;
    }

    // @ts-ignore
    private constructFlagsConfig(option, recordUsedFace = false) {
        if (option.flag1 && !option.flag2) {
            this.config.flag1 = option.flag1;
            this.config.flag1Position = option.flag1Position;
            this.config.flag2 = '';
            this.config.flag2Position = '';
        }
        if (option.flag2 && !option.flag1) {
            this.config.flag1 = option.flag2;
            this.config.flag1Position = option.flag2Position;
            this.config.flag2 = '';
            this.config.flag2Position = '';
        }
        if (option.flag1 && option.flag2) {
            this.config.flag1 = option.flag1;
            this.config.flag1Position = option.flag1Position;
            this.config.flag2 = option.flag2;
            this.config.flag2Position = option.flag2Position;
        }
        if (!option.flag1 && !option.flag2) {
            this.config.flag1 = '';
            this.config.flag1Position = '';
            this.config.flag2 = '';
            this.config.flag2Position = '';
        }
        if (option.flag) {
            this.config.flag1 = option.flag;
            this.config.flag2 = option.flag;
            this.config.flag1Position = 'Right Collar';
            this.config.flag2Position = 'Left Collar';
        }
        /**
         * Since the flags will be on both collars, we will just add the usedFace 'front'
         */
        if (recordUsedFace) {
            this.usedFaces.push('front');
        }
    }

    private needsRegeneratingPreviewUrl() {
        const { embroideryPage, face } = this.props;

        if (face.toLowerCase() !== this.config.prodView || !embroideryPage) {
            return true;
        }

        return !(
            (embroideryPage.type === 'text' && this.config.textLines.length === 0) ||
            (embroideryPage.type === 'logos' && this.config.logos.length === 0) ||
            (embroideryPage.type === 'flags' && !this.config.flag1)
        );
    }

    private mapPlacementsToFaces() {
        const { options } = this.props;

        options.forEach((o) => {
            // Ignore flags and special instructions because they don't have placements
            if (o.type === 'special-instructions' || o.type === 'flags') {
                return;
            }

            o.placementOptions.forEach((po) => {
                if (typeof this.placementsFacesMap[po.frontendValue] === 'undefined') {
                    this.placementsFacesMap[po.frontendValue] = this.getProdViewFromFace(po.face);
                }
            });
        });
    }

    getAllAvailableImages() {
        this.mapPlacementsToFaces();
        this.constructConfigFromEmbroidery(true);

        // If 'front' is in the array of used faces, bring it to the beginning of the array
        this.usedFaces.sort((x, y) => {
            const targetFace = 'front';
            if (x === targetFace) {
                return -1;
            }

            if (y === targetFace) {
                return 1;
            }

            return 0;
        });

        return this.usedFaces.map((face) => {
            this.props.face = face;
            this.constructPreviewUrl();
            return {
                face,
                regular: this.getPreviewUrl(),
                large: this.getZoomPreviewUrl(),
            };
        });
    }
}
